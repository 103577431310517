import './NotFound.css';

function NotFound(){
    return (
        <div className='not-found-page'>
            404! Page Not Found
        </div>
    );
};
export default NotFound;
